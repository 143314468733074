import React from 'react'

const Header = () => {
  return (
    <div id='main'>
        <div className="name">
            <h2>Train insane or  </h2>
            <h1><span>Remain</span>The Same</h1>
            <p className='details'>Reach your fitness goals with Professional touch</p>
            <div className="header-btns">
                <a href="#" className='header-btn'>Join Us</a>
            </div>
        </div>
    </div>
  )
}

export default Header